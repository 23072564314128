.diagnose {
  margin: 50px auto 50px;
  width: 60%;
  .icon {
    width: 80px;
  }
  h3 {
    font-family: PT Sans Narrow;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 58px;
    color: var(--lightBlue);
    margin-top: 10px;
  }
  p {
    margin-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    text-align: justify;
    letter-spacing: 0.05em;
    color: var(--blueNavi);
  }
  .diagnoseImage {
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .diagnose {
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .diagnose {
    width: 100%;
  }
}
