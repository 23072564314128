
#columnaSordeada{
  border-right: white;
}

.team {
  background-color: #f7f7f7;
  padding: 50px 0;
  .teamContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // grid-gap: 50px;
    article {
      text-align: center;
      border-right: 2px solid #b3b3b3;
      img {
        width: 190px;
        height: 190px;
        border-radius: 100px;
      }
      h3 {
        margin: 30px auto 15px;
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 86.9%;
        text-align: center;
        color: #013162;
      }
      h5 {
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 86.9%;
        text-align: center;
        letter-spacing: 0.05em;
        color: var(--aqua);
        margin-bottom: 15px;
        min-height: 50px;
      }
      ul {
        list-style: none;
        text-align: left;
        // border-top: 1px solid #dfdfdf;

        li {
          margin: 20px 20px;
          width: 78%;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 110%;
          color: rgba(0, 0, 0, 0.9);
          display: flex;
          align-items: center;

          span {
            display: block;
            /* height: 5px; */
            /* width: 8px; */
            /* border-radius: 100px; */
            color: var(--aqua);
            margin-right: 8px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .team {
    .teamContainer {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media only screen and (max-width: 600px) {
  .team {
    .teamContainer {
      grid-template-columns: 1fr;
    }
  }
}
