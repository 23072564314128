.director {
  // background-color: var(--lightGray);
  padding: 50px 0;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    span {
      font-weight: bold;
    }
  }
}
.formedBy {
  padding: 50px 0;
  color: white;
  background-color: var(--blueNavi);
  background-image: url("../assets/images/servicesbg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 830px;
  h2 {
    font-family: PT Sans Narrow;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 58px;
    text-align: center;
    margin-bottom: 100px;
  }
  .formedTiles {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 30px;
    margin-bottom: 20px;
    article {
      text-align: center;
      img {
        width: 100px;
      }
      p {
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 47px;
        text-align: center;
      }
      .triangulito{
        color:#a8d42e;
        font-size: 50px;
      }
    }
  }
  #serviciosText{
    font-family: PT Sans Narrow;
    font-style: normal;
    font-size: 35px;
    line-height: 58px;
    text-align: center;
  }
}
.levels {
  padding: 30px 0;
  background-color: #e2e6d2;
  .subtitle{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 43px;
    text-align: center;
    color: #1e234d;
    font-family: PT Sans Narrow;
    span{
      color: var(--blueNavi);
      font-weight: bold;;
    }
    .colorsito{
      color: #a8d42e;
    }
  }
  h2 {
    font-family: PT Sans Narrow;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 78px;
    text-align: center;
    color: var(--blueNavi);
  }
  .levelsExplained {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin-top: 20px;
    article {
      padding: 20px 50px;
      box-shadow: 0px 0px 10px 5px rgba(196, 196, 196, 0.65);
      border-radius: 5px;
      position: relative;
      background-color: white;
      h5 {
        border-radius: 3px;
        background-color: var(--aqua);
        position: absolute;
        padding: 5px 10px;
        top: 0;
        left: 20px;
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 47px;
        text-align: justify;
        color: white;
      }
      h4 {
        color: var(--aqua);
        font-family: PT Sans Narrow;
        font-style: normal;
        margin-top: 51px;
        font-weight: bold;
        font-size: 24px;
        line-height: 47px;
      }
      p {
        color: var(--aqua);
        font-size: 18px;
        font-family: PT Sans Narrow;
        font-weight: bold;
      }
      h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
        text-align: justify;
        color: var(--aqua);
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid var(--aqua);
      }
      ul {
        list-style: none;
        margin-top: 30px;
        li {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.03em;
          display: flex;
          align-items: center;
          margin: 20px 0;
          span {
            display: inline-block;
            height: 5px;
            width: 5px;
            border-radius: 100px;
            background-color: var(--aqua);
            margin-right: 15px;
          }
          &:nth-of-type(4) {
            span {
              width: 8px;
            }
          }
          &:nth-of-type(11) {
            span {
              width: 7px;
            }
          }

          &.unactive {
            color: #c4c4c4;

            span {
              background-color: #c4c4c4;
            }
          }
        }
      }
      &:nth-of-type(2) {
        h5 {
          background-color: #5990ff;
        }
        h4,
        p,
        h3 {
          color: #5990ff;
        }
        h3 {
          border-top: 1px solid #5990ff;
        }
      }
      &:nth-of-type(3) {
        h5 {
          background-color: var(--blueNavi);
        }
        h4,
        p,
        h3 {
          color: var(--blueNavi);
        }
        h3 {
          border-top: 1px solid var(--blueNavi);
        }
      }
    }
  }
  .practices {
    text-align: center;
    margin-top: 50px;
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #1e234d;
      span {
        font-weight: bold;
      }
    }
    button {
      margin-top: 50px;
    }
  }
}
.servicesCFO {
  // background: rgba(89, 144, 255, 0.1);
  padding: 50px 0;
  h2 {
    font-family: PT Sans Narrow;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 78px;
    text-align: center;
    color: var(--blueNavi);
    span {
      color: var(--aqua);
    }
  }
  .servicesTiles {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    margin-top: 50px;
    article {
      text-align: center;
      img {
        width: 100px;
      }
      p {
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 47px;
        text-align: center;
        color: var(--aqua);
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .levels {
    .levelsExplained {
      grid-template-columns: 1fr;
      h4 {
        margin-top: 50px;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .formedBy {
    .formedTiles {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .servicesCFO {
    .servicesTiles {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media only screen and (max-width: 550px) {
  .formedBy {
    .formedTiles {
      grid-template-columns: 1fr;
    }
  }
  .servicesCFO {
    .servicesTiles {
      grid-template-columns: 1fr;
    }
  }
}
