nav {
  position: fixed;
  width: 100%;
  z-index: 50;
  background-color: #fff;
  .contenedorNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 10px 6px;
    max-width: 1297px;
    margin: auto;
  }
  .logo{
    height: 60px;
  }
  ul {
    list-style: none;
    display: flex;
    margin-left: 479px;
    background-color: white;
    li {
      position: relative;
      align-items: center;
      display: inline-flex;
      margin: 0 20px;
      color: var(--blueNavi);
      font-family: "Roboto", sans-serif;
      font-weight: bold;
      font-size: 20px;
      line-height: 58px;
      text-decoration: none;
      button {
        border: 0;
        background-color: transparent;
        color: var(--blueNavi);
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 20px;
        line-height: 58px;
        text-decoration: none;
        cursor: pointer;
        &:focus {
          outline: 0;
        }
      }
      cursor: pointer;
      a {
        color: var(--blueNavi);
        text-decoration: none;
      }
      img {
        width: 35px;
      }
      &:nth-of-type(4) {
        color: var(--aqua);
      }
      article {
        position: absolute;
        display: none;
        top: 40px;
        left: 0;
        background-color: white;
        padding: 10px;
        span {
          display: block;
          min-width: 250px;
          border-bottom: 1px solid white;
          a {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            color: var(--blueNavi);
          }
        }
      }
    }
  }
  .menuButton {
    background-color: transparent;
    border: 0;
    display: none;
    img {
      width: 40px;
    }
  }
}
.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--blueNavi);
  z-index: 40;
  display: none;
  button {
    position: absolute;
    border: 0;
    background-color: transparent;
    top: 20px;
    right: 20px;
    z-index: 300;
    img {
      width: 20px;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    list-style: none;
    li {
      a {
        color: white;
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 20px;
        line-height: 58px;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  nav {
    ul {
      display: none;
    }
    .menuButton {
      display: block;
    }
  }
}
@media only screen and (max-width: 500px) {
  .logo {
    width: 140px;
  }
}
