.levelUp {
  padding: 50px;
  .levelUpContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    .flechita {
      color: #a8d42e;
    }
    article {
      h2 {
        display: flex;
        align-items: center;
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 58px;
        color: #5990ff;
        img {
          width: 80px;
          margin-right: 20px;
        }
        
      }
      p {
        margin-top: 20px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        color: #000000;
      }
      &:first-of-type {
        h2 {
          font-weight: bold;
        }
      }
    }
    .doublePoint {
      grid-column: span 2;
      text-align: center;
      h2 {
        display: inline-flex;
      }
    }
  }
}
.metodology {
  background-color: #e2e6d2;
  padding: 50px 0;
  h2 {
    font-family: PT Sans Narrow;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 78px;
    text-align: center;
    color: #043261;
  }
  .metodologyContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-top: 40px;
    article {
      text-align: center;
      img {
        width: 100px;
      }
      p {
        margin-top: 20px;
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 39px;
        text-align: center;
        color: #5990ff;
      }
    }
  }
}
.timeline {
  padding: 50px 0;
  color: white;
  background-color: var(--blueNavi);
  background-image: url("../assets/images/servicesbg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .timelineContainer {
    display: grid;
    // grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    article {
      text-align: center;
      img {
        // width: 100px;
      }
      p {
        margin-top: 10px;
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 39px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .levelUp {
    .levelUpContainer {
      grid-template-columns: 1fr;

      article {
        text-align: center;
        margin: 20px 0;
        h2 {
          justify-content: center;
        }
      }
      .doublePoint {
        grid-column: span 1;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .metodology {
    .metodologyContainer {
      grid-template-columns: 1fr 1fr;
    }
  }
  .timeline {
    .timelineContainer {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media only screen and (max-width: 550px) {
  .metodology {
    .metodologyContainer {
      grid-template-columns: 1fr;
    }
  }
  .timeline {
    .timelineContainer {
      grid-template-columns: 1fr;
    }
  }
}

