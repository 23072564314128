.operation {
  background-image: url("../assets/images/diagnosebg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .operationContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;

    article {
      img {
        width: 100%;
      }
      &:first-of-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-family: PT Sans Narrow;
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          line-height: 131.9%;
          letter-spacing: -0.01em;
          color: #1e234d;
        }
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.05em;
          color: #000000;
          margin-top: 30px;
        }
        ul {
          list-style: none;
          margin-top: 20px;
          li {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.05em;
            color: #000000;
            display: flex;
            align-items: center;
            span {
              display: inline-block;
              height: 5px;
              width: 5px;
              border-radius: 100px;
              background-color: var(--aqua);
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .operation {
    .operationContainer {
      grid-template-columns: 1fr;
    }
  }
}
@media only screen and (max-width: 550px) {
  .operation {
    .operationContainer {
      article {
        &:first-of-type {
          h2 {
            font-size: 25px;
          }
          p {
            font-size: 20px;
          }
          ul {
            li {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
