footer {
  padding: 20px auto;
  background-color: var(--blueNavi);
  .fixedContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  img {
    height: 60px;
    margin-left: 8vh;
  }
  .linkedin{
    height: 24px;
    margin-right: 8vh;
  }
  p {
    font-family: PT Sans Narrow;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 39px;
    text-align: center;
    color: white;
  }
}
@media only screen and (max-width: 1200px) {
  footer {
    .fixedContainer {
      flex-direction: column;
    }
    img {
      margin: 20px 0 20px;
    }
  }
}
