@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,300&display=swap");

// General Styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --aqua: #a8d42e;
  --blueNavi: #1e234d;
  --lightGray: #f7f7f7;
  --lightBlue: #a8d42e;
}
header {
  background: linear-gradient(
      rgba(30, 35, 77, 0.78) 100%,
      rgba(30, 35, 77, 0.78) 100%
    ),
    url("./assets/images/headerbg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  padding-bottom: 80px;
}



.fixedContainer {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding: 20px;
}
h1 {
  color: white;
  font-family: PT Sans Narrow;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 79px;
  letter-spacing: -0.01em;
  margin-top: 15%;
  span {
    color: var(--aqua);
    display: inline-block;
  }
}
h4 {
  font-family: PT Sans Narrow;
  color: white;
  font-weight: normal;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0.05em;
  margin-top: 10px;
}
.primaryButton {
  font-family: PT Sans Narrow;
  font-weight: bold;
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.065em;
  color: white;
  background-color: var(--aqua);
  border: 0;
  padding: 15px 45px;
  text-transform: uppercase;
}

// Components
.aboutYou {
    padding: 13px 20% 0px 20%;
    background-color: #e2e6d2;
    text-align: center;
    /* width: 60%; */
  

  h3 {
    font-family: PT Sans Narrow;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 131.9%;
    text-align: center;
    letter-spacing: -0.01em;
    color: var(--blueNavi);
  }
  p {
    margin-top: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: black;
  }
  button {
    margin: 40px 0;
  }
}
.typeTiles {
  article {
    padding: 38px;
    h2 {
      font-family: PT Sans Narrow;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 58px;
      color: var(--aqua);
      margin-bottom: 20px;
      span {
        color: white;
      }
    }
    p {
      color: white;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      margin-bottom: 40px;
    }
    a {
      font-family: Roboto;
      font-size: 21px;
      color: var(--aqua);
    }
  }
  .imageLeft {
    display: grid;
    grid-template-columns: 1fr 2fr;
    article {
      &:first-of-type {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      &:last-of-type {
        background-color: var(--blueNavi);
      }
    }
    ul {
      li {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.05em;

        color: #ffffff;
      }
    }
  }

  .imageRight {
    display: grid;
    grid-template-columns: 1fr 2fr;
    article {
      &:first-of-type {
        background-color: var(--blueNavi);
      }
      &:last-of-type {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .firstTile {
    background-image: url("./assets/images/typetiles5.png");
  }
  .secondTile {
    background-image: url("./assets/images/typetiles2.png");
  }
  .thirdTile {
    background-image: url("./assets/images/typetiles3.png");
  }
  .fourthTile {
    background-image: url("./assets/images/typetiles4.png");
  }
  .fitthTile {
    background-image: url("./assets/images/typetiles1.png");
  }
  .sixthTile {
    background-image: url("./assets/images/typetiles6.png");
  }
  .seventhTile {
    background-image: url("./assets/images/typetiles7.png");
  }
}
@media only screen and (max-width: 1200px) {
  .typeTiles {
    .imageRight {
      grid-template-columns: 2fr 1fr;
    }
  }
}
@media only screen and (max-width: 900px) {
  h4 {
    margin-top: 20px;
  }
  header {
    h1 {
      font-size: 40px;
    }
  }
  .typeTiles {
    .imageLeft,
    .imageRight {
      grid-template-columns: 1fr;
    }
    article {
      min-height: 250px;
    }
  }
  .secondTile {
    grid-row: 1;
  }
}

// header {
//   background: 
//     url("./assets/images/capacitacion.jpg");
// }


#headersServicios{
  background:  url("./assets/images/CFO.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 90vh;
  width: 100%;
  padding-bottom: 80px;
}

#headerCapacitacion{
  background:  url("./assets/images/capacitacion.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 90vh;
  width: 100%;
  padding-bottom: 80px;
}

#headersDesarrollos{
  background:  url("./assets/images/desarrollo.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 90vh;
  width: 100%;
  padding-bottom: 80px;
}

#headersFinancieros{
  background:  url("./assets/images/modelosfinancieros.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 90vh;
  width: 100%;
  padding-bottom: 80px;
}

#headersTerminos{
  background-image: none;
  min-height: 0vh;
  width: 100%;
  height: 0vh;
  padding-bottom: 10px;
}
@media only screen and (max-width: 550px) {
  header {
    h1 {
      font-size: 30px;
    }
  }
  .primaryButton {
    font-size: 16px;
  }
  .typeTiles {
    article {
      padding: 30px 20px;
    }
  }
}
