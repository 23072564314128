.types {
  ul {
    list-style: none;
    margin: 40px 0;
    li {
      display: inline-block;
      border-right: 1px solid var(--aqua);
      a {
        text-decoration: none;
        color: white;
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 39px;
        letter-spacing: 0.05em;
        margin: 0 20px;
        text-transform: uppercase;
      }
      &:first-of-type {
        a {
          margin-left: 0;
        }
      }
      &:last-of-type {
        border-right: none;
      }
    }
  }
}
.whyPoints {
  margin: 50px auto;
  .titleContainer {
    margin-bottom: 60px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 2fr;
    h2 {
      font-family: PT Sans Narrow;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 78px;
      color: var(--aqua);
    }
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      color: var(--blueNavi);
      span {
        font-weight: bold;
      }
    }
  }
  .points {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    article {
      h3 {
        color: var(--aqua);
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 39px;
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.05em;
        color: var(--blueNavi);
      }
      img {
        width: 60px;
        margin-bottom: 20px;
      }
    }
  }
}

.clientExperience {
  padding: 65px 0;
  background-color: #e2e6d2;
  height: 50vh;
  .experience {
    display: grid;
    // grid-template-columns: 1fr 2fr;
    article {
      display: flex;
      justify-content: center;
      flex-direction: column;
      img {
        max-width: 300px;
        width: 80%;
      }
      h2 {
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 58px;
        margin-bottom: 50px;
        color: var(--aqua);
        text-align: center;
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.05em;
      }
      &:first-of-type {
        align-items: center;
      }
    }
  }
}
.contact {
  margin: 20px auto;
  width: 75%;
  .formContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    .dataContact {
      h2 {
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 78px;
        color: var(--blueNavi);
      }
      article {
        display: block;
        margin: 20px auto;
        p {
          display: inline-block;
          &:first-of-type {
            font-family: PT Sans Narrow;
            font-weight: bold;
            font-size: 30px;
            line-height: 39px;
            color: var(--aqua);
            margin-right: 10px;
            min-width: 120px;
          }
          &:last-of-type {
            font-family: Roboto;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            text-align: justify;
            letter-spacing: 0.05em;
          }
        }
      }
    }
    .dataForm {
      form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        article {
          margin-bottom: 20px;
          label {
            display: block;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            text-align: justify;
            letter-spacing: 0.05em;
            color: var(--aqua);
          }
          input {
            border: 0;
            border-bottom: 1px solid #1e234d;
            width: 100%;
            max-width: 400px;
            padding: 20px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .whyPoints {
    .titleContainer {
      flex-direction: column;
    }
    .points {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media only screen and (max-width: 900px) {
  .types {
    ul {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      li {
        border: 0;

        a {
          margin: 0;
          padding: 20px;
          font-size: 20px;
        }
      }
    }
  }
  .whyPoints {
    .titleContainer {
      h2 {
        font-size: 40px;
      }
    }
    .points {
      grid-template-columns: 1fr;
      article {
        text-align: center;
      }
    }
  }
  .clientExperience {
    .experience {
      grid-template-columns: 1fr;
    }
  }
  .contact {
    .formContainer {
      grid-template-columns: 1fr;
      .dataForm {
        form {
          article {
            input {
              max-width: 500px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  .contact {
    .formContainer {
      .dataContact {
        article {
          flex-direction: column;
          p {
            &:first-of-type {
              font-size: 20px;
            }
            &:last-of-type {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
